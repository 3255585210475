import { useAppSelector } from '@hooks/useAppSelector';
import {
  COIN_PACK_CAMPAIGN_TOPIC_ID,
  FAIRFIELD_CAMPAIGN_TOPIC_IDS,
  FLIGHT_TICKET_PRIZE_CAMPAIGN_START_DATE,
  FLIGHT_TICKET_PRIZE_CAMPAIGN_STOP_DATE,
  JAL_CAMPAIGN_202409_END,
  JAL_CAMPAIGN_202409_START,
  JAL_CAMPAIGN_202409_TOPIC_URL,
  NEW_STANDARD_CODE,
  SIGN_UP_CAMPAIGN_CODE,
  SIGN_UP_CAMPAIGN_DISCOUNT_AMOUNT,
  TARGET_FAIRFIELD_LIST,
} from '@utils/constants';
import { useIsJapanUser } from '@utils/hooks';
import routes, { route } from '@utils/routes';
import { pushDataLayer } from '@utils/spa-ga4';
import { LANG_LOCALE } from '@utils/types';
import router, { useRouter } from 'next/router';
import { useActiveCoinPack } from './useActiveCoinPack';
import useLocation from './useLocation';
import { useNeighbor } from './useNeighbor';
import useRegistrationCountry from './useRegistrationCountry';

export const useCampaignImagePath =
  () =>
  ({ type }: { type: 'signup' }) => {
    if (type === 'signup') {
      return `/images/campaign/signup/${SIGN_UP_CAMPAIGN_CODE}/`;
    }
  };

export const useIsFlightTicketPrizeCampaignActive = () => {
  const isJapanUser = useIsJapanUser();
  const now = Date.now();

  return (
    isJapanUser &&
    now >= Date.parse(FLIGHT_TICKET_PRIZE_CAMPAIGN_START_DATE) &&
    now <= Date.parse(FLIGHT_TICKET_PRIZE_CAMPAIGN_STOP_DATE)
  );
};

export const useShowFlightTicketPrizeCampaign = () => {
  const isFlightTicketPrizeCampaignActive =
    useIsFlightTicketPrizeCampaignActive();

  const { isSignUpCoinPackageActive } = useActiveCoinPack();

  return isFlightTicketPrizeCampaignActive && !isSignUpCoinPackageActive;
};

export const useIsJalCampaign202409Active = () => {
  const isJapanUser = useIsJapanUser();
  const { neighbor } = useAppSelector((state) => ({
    neighbor: state.neighbor,
  }));

  const isStandardPlan =
    neighbor.neighbor_plans?.[0].code === NEW_STANDARD_CODE;
  const now = Date.now();

  return (
    isJapanUser &&
    isStandardPlan &&
    now >= Date.parse(JAL_CAMPAIGN_202409_START) &&
    now <= Date.parse(JAL_CAMPAIGN_202409_END)
  );
};

export const useGetCampaignBanners = ({
  propertyId,
}: {
  propertyId: number;
}) => {
  const { isLoggedIn } = useNeighbor();
  const showFlightTicketPrizeCampaignActive =
    useShowFlightTicketPrizeCampaign();
  const isJalCampaign202409Active = useIsJalCampaign202409Active();

  const {
    activeCoinPackImagePath,
    isCoinPackCampaignActive,
    showCoinPackPreAnnouncement,
  } = useActiveCoinPack();
  const { locale } = useRouter();

  const banners = TARGET_FAIRFIELD_LIST.includes(propertyId)
    ? [
        {
          alt: 'fairfield by marriott campaign banner',
          link: route(routes.topic, {
            id: FAIRFIELD_CAMPAIGN_TOPIC_IDS[locale as LANG_LOCALE],
          }),
          src: `/images/campaign/fairfield/banner-${locale}.png`,
        },
      ]
    : [];

  // FIXME: Hide until new design is created
  // if (isSignUpCoinPackageActive) {
  //   banners.push({
  //     alt: 'coin pack',
  //     link: routes.coinHistory,
  //     src: `${activeCoinPackImagePath}banner.png?v=2`,
  //   });
  // }

  if (showFlightTicketPrizeCampaignActive && isLoggedIn) {
    banners.push({
      alt: 'コインを買って航空券を当てよう!キャンペーン',
      link: routes.coinHistory,
      src: '/images/campaign/flight-ticket-prize/banner-small.png?v=1',
    });
  }

  if (isJalCampaign202409Active) {
    banners.push({
      alt: 'JAL航空券50%コイン還元キャンペーン',
      link: JAL_CAMPAIGN_202409_TOPIC_URL,
      src: '/images/campaign/jal-campaign-202409/banner.png?v=1',
    });
  }

  if (isCoinPackCampaignActive) {
    banners.push({
      alt: 'coin pack',
      link: routes.coinHistory,
      src: `${activeCoinPackImagePath}banner.png?v=1`,
      type: 'coinpack',
    });
  } else if (showCoinPackPreAnnouncement) {
    banners.push({
      alt: 'coin pack pre',
      link: route(routes.topic, { id: COIN_PACK_CAMPAIGN_TOPIC_ID }),
      src: `${activeCoinPackImagePath}pre-banner.png`,
      type: 'coinpack',
    });
  }

  return banners;
};

export const useCampaignOnClickPushDataLayer = () => {
  const { neighbor } = useNeighbor();
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));

  return ({
    event_name,
    type,
  }: {
    event_name: string;
    type: 'coinpack' | 'signup';
  }) => {
    let campaignCode;

    if (type === 'coinpack') {
      campaignCode = campaigns?.coin_pack?.code || '';
    } else if (type === 'signup') {
      campaignCode = '202410_jp_welcome-coinpack';
    } else {
      campaignCode = '';
    }
    const dataLayerObject = {
      campaign_code: campaignCode,
      event: 'gtm-click',
      event_name,
      neighbor_id: neighbor?.id || '',
    };

    return pushDataLayer(dataLayerObject);
  };
};

export const useIsSignupDiscountCampaignActive = () => {
  const { isJapan } = useLocation();
  const { isLoggedIn } = useNeighbor();
  const { isRegistrationCountryJapan } = useRegistrationCountry();
  const { neighborPlans } = useAppSelector((state) => ({
    neighborPlans: state.neighborPlans,
  }));

  return (
    (isRegistrationCountryJapan || (!isLoggedIn && isJapan)) &&
    neighborPlans.some(
      (plan) => plan.discount.amount === SIGN_UP_CAMPAIGN_DISCOUNT_AMOUNT
    )
  );
};
